.home-container{
    max-width:1100px !important;
    margin-top:30px;
}

.texto-concertina{
    margin-top:30px;
    margin-bottom:30px;
    font-size:1.1rem;
    text-align:justify;
}

.header-concertina {
    text-align:center;
    margin-bottom: 20px
}

.mapa{
    height:500px !important;
    margin-bottom:80px;

}

.leaflet-div-icon{
    background:transparent !important;
    border:none !important;
}