.produto-container{
    max-width:1100px !important;
    margin-top:30px;
}

.texto-produto{
    margin-top:30px;
    margin-bottom:30px;
    font-size:1.1rem;
    text-align: justify;
}

.header-produto{
    text-align:center;
    margin-bottom: 20px
}

.produto-work-gallery, .produto-work-gallery-lg{
    display:flex;
    justify-content:space-around;
    flex-wrap:wrap;
}

.produto-work-gallery img{
    margin-bottom:30px;
    width:30%;
}

.produto-work-gallery-lg img{
    margin-bottom:30px;
    width:45%;
}