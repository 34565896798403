

.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 120px;
  display: flex;
  justify-content: center !important;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding:0px !important;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff !important;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  height:100% !important;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  height:100% !important;
  margin:0px !important;
  justify-content: end;
}

.nav-item {
  height: 100% !important;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor:pointer;
  padding: 0.5rem 3rem;
  height: 100% !important;
}

.nav-links i {
  margin-left:12px
}

.nav-links:hover, .nav-toggle-button:hover {
  color:#fff;
  border-bottom: 4px solid #fff !important;
  transition: all 0.2s ease-out !important;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

.nav-dropdown{
  height: 100%;
}

.nav-toggle-button {
  background-color: transparent !important; 
  border:none !important;
  border-radius: 0 !important;
  height:100%;
  padding: 0.5rem 1rem !important;
  font-size: 1.2rem !important;
}

.nav-toggle-button i{
  margin-left:5px
}

.nav-toggle-menu{
  padding:10px 10px !important;
  background-color: #242424 !important;
}

.nav-toggle-item{
  color:#fff !important;
  padding:10px 15px !important;
  width:100% !important;
}

.nav-toggle-item:hover{

  background-color:#242424 !important;
  border-bottom: 4px solid #fff !important;
  transition: all 0.2s ease-out !important;
}

.nav-toggle-link{
  text-decoration: none !important;
  color:#fff !important;
}

.nav-whatsapp:hover{
  color:#8AF25C!important;
  transition:all ease-in 0.3 !important;
}


@media screen and (max-width: 960px) {
  ul{
    padding:0px !important;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100% !important;
    min-height:100vh;
    transition: all 0.5s ease !important;
  }

  .nav-menu.active {
    background: #242222;
    height:100% !important;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    position: absolute !important;
    top: 120px !important;
  }
  .nav-item{
    margin:0 !important;
    padding:0 !important;
    height:fit-content !important;
  }

  .nav-links {
    text-align: center;
    width:100% !important;
    height:1.5vh !important;
    padding: 2rem;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    margin-left:20px;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 25%;
    bottom: 65%;
    right: 10px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-toggle-button{
    width:100% !important;
    height:100% !important;
    padding: 2rem !important;
  }

  .nav-toggle-button:hover{
    background-color: #fff !important;
    color: #242424 !important;
  }

  .nav-toggle-menu{
    width:100% !important;
  }
  .nav-toggle-link{
    width:100% !important;
  }

  .nav-toggle-item{
    text-align: center !important;
  }
  .nav-whatsapp:hover{
    color:#242424 !important;
  }
}
