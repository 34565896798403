.footer-container {
  position:relative;
  margin-top:-290px;
  clear:both;
  width:100%;
  background-color: #242424;
  padding: 1rem 0 0rem 0;
  height:290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-link-wrapper {
  width:60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.footer-link-items {
  width:100%;
  margin: 30px 0 30px 0;
  display:flex;
  color:#FFF;
  justify-content: space-between;
  font-size:1.1rem;
}

.footer-link-items-centered{
  width:100%;
  margin-top:15px;
  display:flex;
  color:#FFF;
  justify-content: center;
  font-size:1.1rem;
}

.contact-icon{
  margin-right:5px;
}

.footer-link-wrapper > h2 {
  color: #fff;
  margin:0px;
}

.footer-link-items a{
  color:#fff !important;
  text-decoration: none !important;
}
.footer-whatsapp:hover{
  color:#8AF25C!important;
  transition:all ease-in 0.3 !important;
}

.footer-mail:hover{
  color:#1b98e0 !important;
  transition:all ease-in 0.3 !important;
}

.footer-copyright{
  display:flex;
  color:#fff;
  width:100%;
  margin-top:10px;
  font-size:0.9rem;
  justify-content: space-between;
  padding: 20px 0 0 0;
}

.footer-copyright a{
  color:#fff;
  text-decoration:none;
}

.footer-copyright a:hover{
  color:#1b98e0 !important;
  transition:all ease-in 0.3 !important;
}

@media screen and (max-width: 1200px){
  .footer-container{
    margin-top:-325px;
    height:325px;
  }
  .footer-link-wrapper{
    width:80%;
  }
  .footer-link-items{
    display:flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 30px 0 10px 0;
  }
  .footer-link-items div{
    margin-bottom:4px;
  }
}

@media screen and (max-width:800px){
  .footer-container{
    margin-top:-255px;
    height:255px;
  }

  .footer-link-items{
    font-size:1rem;
  }
  
  .footer-link-items-centered{
    display: none;
  }
  .footer-copyright{
    flex-direction: column;
    align-items: center;
    font-size:0.7rem;
    padding:0px;
  }
}

