* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
.container{
  overflow:auto;
  padding-bottom:270px;
}
.main{
  min-height:100%;
}

#root, html, body{
  height:100%;
}

@media screen and (max-width: 1024px){
  .container{
    padding-bottom:325px;
  }
}

@media screen and (max-width:800px){
  .container{
    padding-bottom:255px;
  }
}