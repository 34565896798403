.image-carousel-img{
    width:100%;
    height:600px;
}


@media screen and (max-width:800px){
    .image-carousel-img{
        height:300px !important;
    }
}
